import type { NavigationItemInterface } from '@hypercodestudio/basler-components/dist/models/navigation/NavigationItem';
import { isDefined } from '~/utils/guards/isDefined';
import type { INavigationItem } from '~/lib/ContentfulService';

export function useCreateSecondaryNavItem(
  item: INavigationItem | null | undefined
): NavigationItemInterface | undefined {
  if (!item || !item.fields.showInNavigation) {
    return;
  }

  return {
    items: item.fields?.navigationItems
      ?.map((item) => useCreateSecondaryNavItem(item))
      .filter(isDefined),
    text: item.fields?.linkText ?? '',
    link:
      item.fields?.internalPage || item.fields?.externalLink
        ? useBuildLinkInterface(item)
        : undefined
  } satisfies NavigationItemInterface;
}
