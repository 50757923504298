import type { Ref } from 'vue';
import type {
  Props as SearchBoxInterface,
  SearchTypes
} from '@hypercodestudio/basler-components/dist/components/layout/Header/SearchBox.vue';
import type { NavigationItemInterface } from '@hypercodestudio/basler-components/dist/models/navigation/NavigationItem';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import type { UseGlobalSearchOptions } from '~/composables/useGlobalSearch';
import { useGlobalSearch } from '~/composables/useGlobalSearch';
import { buildUrlString } from '~/utils/buildUrlString';
import { isDefined } from '~/utils/guards/isDefined';
import { DOCUMENTS_TAG, PRODUCTS_TAG } from '~/lib/AlgoliaService/constants';
import { transformSearchResultToArticleTeaser } from '~/utils/transformSearchResultToArticleTeaser';
import { debounce } from '~/composables/useDebounce';
import type { ResolvedGlobalPageSettings } from '~/lib/ContentfulGraphqlService';
import { createSearchTags } from '~/utils/createDictKeysForSearchTags';
import { useHTMLDictionary } from '~/composables/useHTMLDictionary';

/**
 * Composable for page header search box.
 */
export function useSearchBox(
  globalPageSettings: Ref<ResolvedGlobalPageSettings | null>
) {
  const { $analytics, $breakpoints, $textDictionary } = useNuxtApp();
  const locale = useLocale();
  const router = useRouter();
  const htmlDictionary = useHTMLDictionary([
    'pageHeader.searchBox.noItems.text.message'
  ]);

  const searchConfiguration = shallowRef({
    limit: 3
  } satisfies UseGlobalSearchOptions);
  const ALL_SEARCH_TYPE = '_all';
  const {
    fetchResults,
    searchQuery,
    searchInputValue,
    searchResponse,
    activeType,
    currentResults,
    loading
  } = useGlobalSearch(searchConfiguration);

  const contentFilters = computed(() =>
    createSearchTags(
      globalPageSettings.value?.searchAllowedTags,
      $textDictionary.value,
      locale.value
    )
  );
  const tags = computed(() => [
    ...contentFilters.value,
    {
      value: PRODUCTS_TAG,
      label:
        $textDictionary.value['searchSection.searchResults.filter.products']
    },
    {
      value: DOCUMENTS_TAG,
      label:
        $textDictionary.value['searchSection.searchResults.filter.documents']
    }
  ]);

  function createAllSearchResultsLink(
    searchQuery: string,
    activeType: string | null
  ): string {
    const queryParams = new URLSearchParams();
    queryParams.append('q', searchQuery);
    if (activeType) {
      queryParams.append('type', activeType);
    }

    return buildUrlString(
      locale.value,
      globalPageSettings.value?.searchResultsPage?.metadata?.slug ?? '#',
      queryParams.toString()
    );
  }

  const allSearchResultsLink = computed(() =>
    createAllSearchResultsLink(searchQuery.value, activeType.value)
  );
  const searchBox = computed((): SearchBoxInterface => {
    const noItemsLink = globalPageSettings.value?.searchNoItemsLink?.metadata
      ?.slug
      ? buildUrlString(
          locale.value,
          globalPageSettings.value.searchNoItemsLink.metadata.slug
        )
      : undefined;
    const popularSearchesItems =
      globalPageSettings.value?.searchPopularSearchesCollection?.items?.filter(
        isDefined
      ) ?? [];
    const popularSearches =
      // only show popular searches if nothing has been searched
      searchQuery.value.trim() === '' && popularSearchesItems.length > 0
        ? {
            title:
              $textDictionary.value[
                'pageHeader.searchBox.popularSearches.title.label'
              ] ?? '',
            items: popularSearchesItems.map(
              (link) =>
                ({
                  isActive: false,
                  text: link.linkText ?? '',
                  link: buildLinkInterface(link, locale.value)
                } satisfies NavigationItemInterface)
            )
          }
        : undefined;

    return {
      isOpen: false,
      searchTypes: {
        label:
          $textDictionary.value['pageHeader.searchBox.searchTypes.label'] ?? '',
        options: Object.fromEntries([
          [
            ALL_SEARCH_TYPE,
            $textDictionary.value[
              'pageHeader.searchBox.searchTypes.all.label'
            ] ?? ''
          ],
          ...tags.value.map((filter) => [filter.value, filter.label])
        ])
      } satisfies SearchTypes,
      formData: {
        'search-type': activeType.value ?? ALL_SEARCH_TYPE,
        'search-value': searchQuery.value
        // XXX: wrong type?
      } as any,
      popularSearches,
      results:
        searchResponse.value != null && searchInputValue.value.trim() !== ''
          ? {
              title:
                $textDictionary.value[
                  'pageHeader.searchBox.results.title.label'
                ] ?? '',
              isLoading: loading.value,
              showAll: {
                text:
                  $textDictionary.value[
                    'pageHeader.searchBox.showAll.text.label'
                  ] ?? '',
                link: {
                  uri: allSearchResultsLink.value,
                  external: false,
                  download: undefined
                } satisfies HyperlinkInterface
              },
              items: currentResults.value
                .map((result) =>
                  transformSearchResultToArticleTeaser(
                    result,
                    tags.value,
                    locale.value
                  )
                )
                .filter(isDefined),
              noItems: {
                text:
                  htmlDictionary.value[
                    'pageHeader.searchBox.noItems.text.message'
                  ] ?? '',
                linkItem: noItemsLink
                  ? {
                      title:
                        $textDictionary.value[
                          'pageHeader.searchBox.noItem.link.label'
                        ] ?? '',
                      link: noItemsLink
                    }
                  : undefined
              }
            }
          : undefined,
      closeButtonLabel:
        $textDictionary.value['pageHeader.searchBox.closeButton.label'] ?? '',
      searchInputLabel:
        $textDictionary.value[
          'searchSection.searchResults.searchPlaceholder'
        ] ?? '',
      searchInputPlaceholder:
        $textDictionary.value[
          'searchSection.searchResults.searchPlaceholder'
        ] ?? ''
    };
  });

  function handleSearchFormSubmit(data: object) {
    const newSearchValue =
      'search-value' in data && typeof data['search-value'] === 'string'
        ? data['search-value'] ?? ''
        : '';

    return router.push(
      createAllSearchResultsLink(newSearchValue, activeType.value)
    );
  }

  function handleSearchTypeChange(value: string | number | undefined) {
    let newActiveType = typeof value === 'string' ? value : null;
    if (newActiveType === ALL_SEARCH_TYPE) {
      newActiveType = null;
    }

    activeType.value = newActiveType;
  }

  function handleSearchValueChange(newValue: string) {
    // search results are not display on mobile, therefore to not
    // update the refs.
    if ($breakpoints.specialHeaderBreakpoint) {
      return;
    }

    searchQuery.value = newValue;
  }

  watch(activeType, () =>
    // fetchResults has internal error handling
    fetchResults(searchQuery.value, activeType.value)
  );

  watch(
    searchQuery,
    debounce(async () => {
      // fetchResults has internal error handling
      await fetchResults(searchQuery.value, activeType.value);

      $analytics?.pushToDataLayer({
        event: 'search',
        eventInfo: {
          search_term: searchQuery.value
        }
      });
    }, 300)
  );

  return {
    searchBox,
    handleSearchFormSubmit,
    handleSearchTypeChange,
    handleSearchValueChange
  };
}
