import type { WritableComputedRef } from 'vue';
import { v } from 'h3-valibot';

export const userIdCookieSchema = v.nullable(v.string());

export type userIdCookieSchemaModel = v.InferOutput<typeof userIdCookieSchema>;

/**
 * Prefer access via {@link useGeoinformationStore}.
 *
 * @internal
 */
export function useUserIdCookie(): WritableComputedRef<
  userIdCookieSchemaModel | undefined
> {
  const name = 'user_id';
  const cookie = useCookie<userIdCookieSchemaModel | undefined>(name, {
    secure: true,
    // can not use `decode` method, as `decode` is performed on *every* cookie
    // resulting in zod errors if schema validation is performed in decode.
    // therefore, we expose a computed value and a setter.
    maxAge: 60 * 60 * 24 * 30 // 30 days
  });

  return computed<userIdCookieSchemaModel | undefined>({
    get() {
      const result = v.safeParse(userIdCookieSchema, cookie.value);

      if (result.success) {
        return result.output;
      }
    },
    set(newValue) {
      cookie.value = newValue;
    }
  });
}
